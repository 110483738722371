import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      message: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      message = "Исчерпан лимит запросов для анонимных пользователей"
    }) {
      this.message = message;
    }
  },
  components: {
    Modal,
    Wrapper
  }
};