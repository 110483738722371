import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal"
  }, [_c('wrapper', {
    scopedSlots: _vm._u([{
      key: "alert",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.message) + " ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Для доступа к этому функционалу нужно"), _c('br'), _vm._v(" перейти на старший тариф ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_vm._v(" Сменить тариф вы можете в разделе \"Тариф и оплата\" "), _c('div', {
          staticClass: "mt24"
        }, [_c('UiButton', {
          attrs: {
            "variant": "blue"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push('/profile/plan');
            }
          }
        }, [_vm._v(" Перейти в личный кабинет ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };